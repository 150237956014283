'use strict'

/**
 * @memberof nps
 * @module view
 * @function
 * @returns {Promise} view
 */
export function view () {
  return this.request.patch(`${this.urlNps}/me/view-nps`)
}
