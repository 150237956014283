import { getTimezones, getThemes, getLanguages, getTypes, getRoles, getGateways, removeEmojis, removeSpecialCharacters, getBrandByBin, getAddressByZipCode, isValidCNPJ, isValidCPF, getIntegrationList, getSegmentList } from './read.js'

/**
 * @namespace commons
 * @memberof UtilitiesCore
 * @instance
 */
export default function commons () {
  return {
    getTimezones: getTimezones.bind(this),
    getThemes: getThemes.bind(this),
    getLanguages: getLanguages.bind(this),
    getTypes: getTypes.bind(this),
    getRoles: getRoles.bind(this),
    getGateways: getGateways.bind(this),
    getAddressByZipCode: getAddressByZipCode.bind(this),
    getBrandByBin: getBrandByBin.bind(this),
    removeEmojis: removeEmojis.bind(this),
    removeSpecialCharacters: removeSpecialCharacters.bind(this),
    isValidCNPJ: isValidCNPJ.bind(this),
    isValidCPF: isValidCPF.bind(this),
    getIntegrationList: getIntegrationList.bind(this),
    getSegmentList: getSegmentList.bind(this)
  }
}
