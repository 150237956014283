'use strict'
import commons from './commons'
import nps from './nps'
import { requestNoHeader } from './support/http/requestNoHeader'
import { request } from './support/http/request'

let UtilitiesInstance = null

const domains = {
  mundipagg: '.mundipagg.com',
  pagarme: '.pagar.me'
}

export default class UtilitiesCore {
  constructor ({ environment = 'production', theme = 'mundipagg' }) {
    this.commons = commons.bind(this)()
    this.nps = nps.bind(this)()
    this.requestNoHeader = requestNoHeader
    this.request = request
    this._env = environment
    this.theme = theme
    if (!UtilitiesInstance) {
      UtilitiesInstance = this
    }
    return UtilitiesInstance
  }

  static getInstance () {
    if (!UtilitiesInstance) {
      UtilitiesInstance = new UtilitiesCore({})
    }
    return UtilitiesInstance
  }

  get domain () {
    return domains[this.theme] || '.mundipagg.com'
  }

  get url () {
    let url
    switch (this._env) {
      case 'production':
        url = 'https://api.mundipagg.com/utilities/v2/graphql'
        break
      case 'contingency':
        url = 'https://ctgapi.mundipagg.com/utilities/v2/graphql'
        break
      case 'staging':
        url = 'https://stgapi.mundipagg.com/utilities/v2/graphql'
        break
      case 'development':
        url = 'https://devapi.mundipagg.com/utilities/v2/graphql'
        break
      default:
        url = 'https://api.mundipagg.com/utilities/v2/graphql'
    }
    return url
  }

  get urlNps () {
    let urlNps
    switch (this._env) {
      case 'production':
        urlNps = 'https://api.mundipagg.com/nps/v2'
        break
      case 'contingency':
        urlNps = 'https://ctgapi.mundipagg.com/nps/v2'
        break
      case 'staging':
        urlNps = 'https://stgapi.mundipagg.com/nps/v2'
        break
      case 'development':
        urlNps = 'https://devapi.mundipagg.com/nps/v2'
        break
      default:
        urlNps = 'https://api.mundipagg.com/nps/v2'
    }
    return urlNps
  }

  get mapsApi () {
    return 'https://api.mundipagg.com/maps/v1.0'
  }

  get envAcronym () {
    let acronym
    switch (this._env) {
      case 'contingency':
      case 'production':
        acronym = ''
        break
      case 'staging':
        acronym = 'stg_'
        break
      case 'development':
        acronym = 'dev_'
        break
      default:
        acronym = ''
    }
    return acronym
  }

  paramSerializer (params = {}) {
    let queryParams = Object.entries(params)
    if (queryParams.length > 0) {
      queryParams = `?${new URLSearchParams(queryParams)}`
    } return queryParams
  }
}
