'use strict'

/**
 * @memberof nps
 * @module getMe
 * @function
 * @returns {Promise} getMe
 */
export function getMe () {
  return this.request.get(`${this.urlNps}/me`)
}
