'use strict'
// import http from './http'
import axios from 'axios'

import { requestInterceptorNoHeader, errorRequestInterceptor, responseInterceptor, errorResponseInterceptor } from './interceptors.js'

export const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(requestInterceptorNoHeader, errorRequestInterceptor)
axiosInstance.interceptors.response.use(responseInterceptor, errorResponseInterceptor)

export const requestNoHeader = {
  get: (endpoint) => axiosInstance.get(endpoint).catch(e => Promise.reject(e)),
  post: (endpoint, body) => axiosInstance.post(endpoint, body).catch(e => Promise.reject(e)),
  delete: (endpoint, body) => axiosInstance.delete(endpoint, { data: body }).catch(e => Promise.reject(e)),
  put: (endpoint, body) => axiosInstance.put(endpoint, body).catch(e => Promise.reject(e)),
  patch: (endpoint, body) => axiosInstance.patch(endpoint, body).catch(e => Promise.reject(e))
}
