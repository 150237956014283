'use strict'

/**
 * @memberof nps
 * @module setAnswer
 * @function
 * @returns {Promise} setAnswer
 */
export function setAnswer (body) {
  return this.request.post(`${this.urlNps}/answers`, body)
}
