'use strict'

function setSimpleQuery (queryName, field, nestedFields) {
  return { query: `query ${queryName} ` + `{ ${field} ${nestedFields ? '{ ' + nestedFields + ' }' : ''} }` }
}

/**
 * @memberof commons
 * @module getTimezones
 * @function
 * @returns {Promise} Timezones
 */
export function getTimezones () {
  const timezonesFields = ['key', 'value', 'abbreviation', 'utc', 'offset', 'is_daylight_saving_time']
  const timezonesQuery = setSimpleQuery('UtilitiesQuery', 'timezones', timezonesFields)
  return this.request.post(`${this.url}`, timezonesQuery)
    .then(res => res.data.timezones)
}

/**
 * @memberof commons
 * @module getThemes
 * @function
 * @returns {Promise} Timezones
 */
export function getThemes () {
  const themesFields = ['key', 'value', 'modes', 'urls', '{ name, url }']
  const themesQuery = setSimpleQuery('UtilitiesQuery', 'themes', themesFields)
  return this.request.post(`${this.url}`, themesQuery)
    .then(res => res.data.themes)
}

/**
 * @memberof commons
 * @module getLanguages
 * @function
 * @returns {Promise} Timezones
 */
export function getLanguages () {
  const languagesFields = ['key', 'value']
  const languagesQuery = setSimpleQuery('UtilitiesQuery', 'languages', languagesFields)
  return this.request.post(`${this.url}`, languagesQuery)
    .then(res => res.data.languages)
}

/**
 * @memberof commons
 * @module getTypes
 * @function
 * @returns {Promise} Types
 */
export function getTypes () {
  const typesFields = ['key', 'value']
  const typesQuery = setSimpleQuery('UtilitiesQuery', 'types', typesFields)
  return this.request.post(`${this.url}`, typesQuery)
    .then(res => res.data.types)
}
/**
 * @memberof commons
 * @module getRoles
 * @function
 * @returns {Promise} Roles
 */
export function getRoles () {
  const rolesFields = ['key', 'value']
  const rolesQuery = setSimpleQuery('UtilitiesQuery', 'roles', rolesFields)
  return this.request.post(`${this.url}`, rolesQuery)
    .then(res => res.data.roles)
}

/**
 * @memberof commons
 * @module getGateways
 * @function
 * @returns {Promise} Gateways
 */
export function getGateways () {
  const gatewaysFields = ['key', 'value']
  const gatewaysQuery = setSimpleQuery('UtilitiesQuery', 'gateways', gatewaysFields)
  return this.request.post(`${this.url}`, gatewaysQuery)
    .then(res => res.data.gateways)
}

/**
 * @memberof commons
 * @module getAddressByZipCode
 * @function
 * @param {string} zipcode - Zipcode number
 * @returns {Promise} Address info
 */
export function getAddressByZipCode (zipcode) {
  return this.requestNoHeader.get(`${this.mapsApi}/BR/${zipcode}`)
}

/**
 * @memberof commons
 * @module getBrandByBin
 * @function
 * @param {string} bin - Bin number
 * @returns {Promise} Brand info
 */
export function getBrandByBin (bin) {
  const brandFields = ['key', 'name', 'gaps', 'lenghts', 'mask', 'cvv', 'image', 'bins']
  // eslint-disable-next-line
  const brandQuery = setSimpleQuery('UtilitiesQuery', `brands (bin: \"${bin}\")`, brandFields)
  return this.request.post(`${this.url}/graphql`, JSON.stringify(brandQuery))
}

/**
 * @memberof commons
 * @module removeEmojis
 * @function
 * @returns {string} string without emoji characters
 */
export function removeEmojis (string) {
  var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g

  return string.replace(regex, '')
}

/**
 * @memberof commons
 * @module removeSpecialCharacters
 * @function
 * @returns {string} string without especial characters
 */
export function removeSpecialCharacters (string, type = 'name') {
  let value

  switch (type) {
    case 'userName':
      // eslint-disable-next-line
      value = removeEmojis(string.replace(/[`~!@#$%^&*()|+\-=?;:", <>\{\}\[\]\\\/]/gi, ''))
      break
    case 'document':
      // eslint-disable-next-line
      value = removeEmojis(string.replace(/[`~!@#$%^&*()|+\=?;:", <>\{\}\[\]\\\/]/gi, ''))
      break
    case 'phone':
      // eslint-disable-next-line
      value = removeEmojis(string.replace(/[`~!@$%^&|\=?;:",<>\{\}\[\]\\\/]|[A-z]/gi, ''))
      break
    case 'email':
      // eslint-disable-next-line
      value = removeEmojis(string.replace(/[`~!#$%^&*()|+\=?;:", <>\{\}\[\]\\\/]/gi, ''))
      break
    case 'name':
    default:
      // eslint-disable-next-line
      value = removeEmojis(string.replace(/[`~!@#$%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi, ''))
  }

  return value
}

/**
 * @memberof commons
 * @module isValidCNPJ
 * @function
 * @returns {Boolean}  Boolean information if is a valid CNPJ
 */
export function isValidCNPJ (cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '')
  if (cnpj === '') return false
  if (cnpj.length !== 14) return false
  // Elimina CNPJs invalidos conhecidos
  if (cnpj === '00000000000000' || cnpj === '11111111111111' || cnpj === '22222222222222' || cnpj === '33333333333333' || cnpj === '44444444444444' || cnpj === '55555555555555' || cnpj === '66666666666666' || cnpj === '77777777777777' || cnpj === '88888888888888' || cnpj === '99999999999999') {
    return false
  }
  // Valida DVs
  var size = cnpj.length - 2
  var numbers = cnpj.substring(0, size)
  var dig = cnpj.substring(size)
  var sum = 0
  var pos = size - 7
  var i
  for (i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  var result = sum % 11 < 2 ? 0 : 11 - sum % 11
  if (result.toString() !== dig.charAt(0)) {
    return false
  }
  size = size + 1
  numbers = cnpj.substring(0, size)
  sum = 0
  pos = size - 7
  for (i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  result = sum % 11 < 2 ? 0 : 11 - sum % 11
  if (result.toString() !== dig.charAt(1)) {
    return false
  }
  return true
}

/**
 * @memberof commons
 * @module isValidCPF
 * @function
 * @returns {Boolean}  Boolean information if is a valid CPF
 */

export function isValidCPF (cpf) {
  // eslint-disable-next-line
  cpf = cpf.replace(/[^\d]+/g,'')
  if (cpf === '') return false
  // Elimina CPFs invalidos conhecidos
  if (cpf.length !== 11 || cpf === '00000000000' || cpf === '11111111111' || cpf === '22222222222' || cpf === '33333333333' || cpf === '44444444444' || cpf === '55555555555' || cpf === '66666666666' || cpf === '77777777777' || cpf === '88888888888' || cpf === '99999999999') {
    return false
  }
  var add, rev, i
  // Valida 1o digito
  add = 0
  for (i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i)) * (10 - i)
  }
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }
  if (rev !== parseInt(cpf.charAt(9))) {
    return false
  }
  // Valida 2o digito
  add = 0
  for (i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i)) * (11 - i)
  }
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }
  if (rev !== parseInt(cpf.charAt(10))) {
    return false
  }
  return true
}

/**
 * @memberof commons
 * @module getIntegrationList
 * @function
 * @returns {Array} List of possibilities integrations
 */
export function getIntegrationList () {
  const integrationList = [
    {
      name: 'Agência',
      id: 2,
      maintainer: [
        { name: 'Agência', id: 37 }
      ]
    },
    {
      name: 'Desenvolvedor próprio',
      id: 3,
      maintainer: [
        { name: 'Desenvolvedor Próprio', id: 38 }
      ]
    },
    {
      name: 'DevHouse',
      id: 4,
      maintainer: [
        { name: 'DevHouse', id: 39 }
      ]
    },
    {
      name: 'ERP',
      id: 5,
      maintainer: [
        { name: 'Eccosys', id: 35 },
        { name: 'KPL', id: 33 },
        { name: 'Softvar', id: 34 }
      ]
    },
    {
      name: 'Full Commerce',
      id: 6,
      maintainer: [
        { name: 'Full Comerce', id: 40 }
      ]
    },
    {
      name: 'Gateway',
      id: 7,
      maintainer: [
        { name: 'Gateway', id: 41 }
      ]
    },
    {
      name: 'Loja Teste',
      id: 1,
      maintainer: [
        { name: 'Loja Teste', id: 36 }
      ]
    },
    {
      name: 'Modo Call Center',
      id: 11,
      maintainer: [
        { name: 'Modo Call Center', id: 44 }
      ]
    },
    {
      name: 'Não Classificado',
      id: 12,
      maintainer: [
        { name: 'Não Classificado', id: 45 },
        { name: 'Não Classificado', id: 172 }
      ]
    },
    {
      name: 'Parceiro',
      id: 8,
      maintainer: [
        { name: 'Parceiro', id: 42 }
      ]
    },
    {
      name: 'Plataforma',
      id: 9,
      maintainer: [
        { name: 'AG Commerce', id: 1 },
        { name: 'Aztronic', id: 2 },
        { name: 'B Seller', id: 4 },
        { name: 'Beta Labs', id: 5 },
        { name: 'Bis2Bis', id: 6 },
        { name: 'Biz Commerce', id: 7 },
        { name: 'BlueFoot', id: 8 },
        { name: 'Comm2', id: 9 },
        { name: 'DigitalHub', id: 10 },
        { name: 'e-smart', id: 11 },
        { name: 'EZCommerce', id: 12 },
        { name: 'FBITS', id: 13 },
        { name: 'Full Solutions', id: 14 },
        { name: 'Giran/Adena', id: 3 },
        { name: 'Híbrido (Achei Negócios)', id: 15 },
        { name: 'Ideais', id: 16 },
        { name: 'Innersite', id: 17 },
        { name: 'Irroba', id: 32 },
        { name: 'JET e-commerce', id: 18 },
        { name: 'MaximaWeb', id: 31 },
        { name: 'Moovin', id: 19 },
        { name: 'NetFashion', id: 20 },
        { name: 'PRIMORDIA', id: 21 },
        { name: 'Signativa', id: 22 },
        { name: 'SMARTBILL', id: 23 },
        { name: 'TATIX', id: 24 },
        { name: 'Trezo', id: 25 },
        { name: 'UECOMMERCE', id: 26 },
        { name: 'VANNON', id: 27 },
        { name: 'VTEX', id: 28 },
        { name: 'WEBJUMP', id: 29 },
        { name: 'Xtech', id: 30 }
      ]
    },
    {
      name: 'Software',
      id: 10,
      maintainer: [
        { name: 'Software', id: 43 }
      ]
    }
  ]
  return integrationList
}

/**
 * @memberof commons
 * @module getSegmentList
 * @function
 * @returns {Array} List of possibilities Segments
 */
export function getSegmentList () {
  const segmentList = [
    {
      id: 15,
      name: 'Alimentação e Bebidas',
      subsegment: [
        { id: 74, name: 'Alimentação Rápida' },
        { id: 75, name: 'Bares e Restaurantes' },
        { id: 76, name: 'Comércio de Alimentos' },
        { id: 77, name: 'Comércio de Bebidas' },
        { id: 78, name: 'Suplementos' }
      ]
    },
    {
      id: 22,
      name: 'Autopeças e Serviços Automotivos',
      subsegment: [
        { id: 118, name: 'Autopeças e Acessórios' },
        { id: 119, name: 'Autopeças e Serviços Automotivos - Outros' },
        { id: 120, name: 'Estacionamentos e Lava-rápidos' },
        { id: 121, name: 'Oficinas Automotivas' }
      ]
    },
    {
      id: 3,
      name: 'Casa e Decoração',
      subsegment: [
        { id: 22, name: 'Armarinhos e Tecido' },
        { id: 23, name: 'Artigos de Decoração' },
        { id: 24, name: 'Cama, Mesa e Banho' },
        { id: 25, name: 'Casa e Decoração - Outros' },
        { id: 26, name: 'Jardinagem' },
        { id: 27, name: 'Móveis' }
      ]
    },
    {
      id: 38,
      name: 'Cias Aéreas',
      subsegment: [
        { id: 187, name: 'Cias Aéreas' }
      ]
    },
    {
      id: 38,
      name: 'Comércio de Veículos',
      subsegment: [
        { id: 184, name: 'Comércio de Veículos' }
      ]
    },
    {
      id: 6,
      name: 'Consertos, Obras e Manutenção',
      subsegment: [
        { id: 33, name: 'Conserto de Produtos e Reparos de Peças' },
        { id: 34, name: 'Dedetização e Desinfecção' },
        { id: 35, name: 'Paisagismo e Jardinagem' },
        { id: 36, name: 'Reformas e Obras em Geral' }
      ]
    },
    {
      id: 26,
      name: 'Crowdfunding',
      subsegment: [
        { id: 130, name: 'Crowdfunding' }
      ]
    },
    {
      id: 42,
      name: 'Doação',
      subsegment: [
        { id: 196, name: 'ONGs e Similares' },
        { id: 197, name: 'Outros Doação - Outros' },
        { id: 198, name: 'Política' },
        { id: 199, name: 'Religião' }
      ]
    },
    {
      id: 27,
      name: 'Educação',
      subsegment: [
        { id: 134, name: 'Cursos Preparatórios' },
        { id: 135, name: 'EAD - Cursos Preparatórios' },
        { id: 136, name: 'EAD - Extracurriculares e Outros' },
        { id: 137, name: 'EAD - Superior e Técnico' },
        { id: 138, name: 'Ensino Básico' },
        { id: 139, name: 'Ensino Superior e Técnico' },
        { id: 140, name: 'Extracurriculares, Autoescola e Outros' }
      ]
    },
    {
      id: 12,
      name: 'Eletroeletrônicos',
      subsegment: [
        { id: 7, name: 'Celulares e Computadores' },
        { id: 48, name: 'Eletrodomésticos' },
        { id: 49, name: 'Eletrônicos' },
        { id: 50, name: 'Softwares e Eletrônica Integrada' }
      ]
    },
    {
      id: 21,
      name: 'Entretenimento e Turismo',
      subsegment: [
        { id: 115, name: 'Entretenimento e Turismo' }
      ]
    },
    {
      id: 4,
      name: 'Erótica',
      subsegment: [
        { id: 28, name: 'Conteúdo Erótico e Outros' },
        { id: 29, name: 'Sex Shop' }
      ]
    },
    {
      id: 20,
      name: 'Esporte e Fitness',
      subsegment: [
        { id: 107, name: 'Academias e Clubes' },
        { id: 108, name: 'Artigos Esportivos' },
        { id: 109, name: 'Esporte e Fitness - Outros' }
      ]
    },
    {
      id: 37,
      name: 'Farmácias, Produtos de Saúde e Cosmética',
      subsegment: [
        { id: 179, name: 'Cosméticos e Perfumaria' },
        { id: 180, name: 'Drogarias e Farmácias' },
        { id: 181, name: 'Outros Produtos de Saúde e Beleza' }
      ]
    },
    {
      id: 16,
      name: 'Ingressos, Eventos e Festas',
      subsegment: [
        { id: 79, name: 'Festas e Eventos' },
        { id: 80, name: 'Ingressos' }
      ]
    },
    {
      id: 10,
      name: 'Jogos e Brinquedos',
      subsegment: [
        { id: 44, name: 'Jogos de Videogame' },
        { id: 45, name: 'Jogos Digitais' },
        { id: 46, name: 'Jogos e Brinquedos Físicos' }
      ]
    },
    {
      id: 17,
      name: 'Livrarias, Papelarias, Gráficas e Bancas',
      subsegment: [
        { id: 85, name: 'Gráfica, Impressão e Xerox' },
        { id: 86, name: 'Jornais e Revistas - Conteúdo Digital' },
        { id: 87, name: 'Jornais e Revistas - Conteúdo Físico' },
        { id: 88, name: 'Livrarias e Papelarias' }
      ]
    },
    {
      id: 38,
      name: 'Logística e Mobilidade',
      subsegment: [
        { id: 184, name: 'Apps de Mobilidade Urbana' },
        { id: 185, name: 'Delivery e Entrega' },
        { id: 186, name: 'Locação de Veículos' },
        { id: 187, name: 'Logística e Mobilidade - Outros' },
        { id: 188, name: 'Táxi/Carona' }
      ]
    },
    {
      id: 8,
      name: 'Lojas de Departamento',
      subsegment: [
        { id: 39, name: 'Lojas de Departamento' }
      ]
    },
    {
      id: 6,
      name: 'Materiais de Construção',
      subsegment: [
        { id: 33, name: 'Materiais de Construção' }
      ]
    },
    {
      id: 2,
      name: 'Moda e Acessórios',
      subsegment: [
        { id: 2, name: 'Acessórios, Bolsas e Bijuterias' },
        { id: 6, name: 'Calçados' },
        { id: 7, name: 'Joalherias, Relojoarias e Pratarias' },
        { id: 2, name: 'Moda e Acessórios - Outros' },
        { id: 2, name: 'Óticas e Óculos' },
        { id: 15, name: 'Vestuário' }
      ]
    },
    {
      id: 31,
      name: 'Musica e Video',
      subsegment: [
        { id: 150, name: 'Estúdios e Gravadoras' },
        { id: 151, name: 'Instrumentos Musicais, CDs, DVDs e Outros' },
        { id: 152, name: 'Música e Vídeo - Conteúdo Digital' }
      ]
    },
    {
      id: 13,
      name: 'Outros Comércios',
      subsegment: [
        { id: 55, name: 'Artigos Religiosos e Antiguidades' },
        { id: 56, name: 'Equipamentos de Uso Comercial e Industrial' },
        { id: 57, name: 'Floricultura' },
        { id: 58, name: 'Loja de Presentes' },
        { id: 59, name: 'Tabacaria' }
      ]
    },
    {
      id: 42,
      name: 'Outros Serviços',
      subsegment: [
        { id: 193, name: 'Anúncios' },
        { id: 194, name: 'Apostas' },
        { id: 195, name: 'Associação' },
        { id: 196, name: 'Certificadores' },
        { id: 197, name: 'Jurídico' },
        { id: 198, name: 'Outros Serviços - Outros' },
        { id: 199, name: 'Serviços Financeiros' }
      ]
    },
    {
      id: 25,
      name: 'Petshops e Veterinários',
      subsegment: [
        { id: 28, name: 'Petshops' },
        { id: 28, name: 'Veterinários' }
      ]
    },
    {
      id: 36,
      name: 'Plataformas e Serviços Digitais',
      subsegment: [
        { id: 169, name: 'Agência de E-commerce / Plataforma' },
        { id: 168, name: 'Anúncios Online' },
        { id: 169, name: 'Cupons e Descontos' },
        { id: 170, name: 'Delivery e Entrega' },
        { id: 167, name: 'ERP/SaaS' },
        { id: 171, name: 'Ingressos' },
        { id: 172, name: 'Programa de Fidelidade' },
        { id: 174, name: 'Serviços Digitais - Outros' },
        { id: 175, name: 'Software' }
      ]
    },
    {
      id: 42,
      name: 'Postos de Gasolina e Similares',
      subsegment: [
        { id: 193, name: 'Gás GLP, Lubrificantes e Outros' },
        { id: 194, name: 'Postos de Gasolina' }
      ]
    },
    {
      id: 42,
      name: 'Serviços',
      subsegment: [
        { id: 193, name: 'Serviços' }
      ]
    },
    {
      id: 28,
      name: 'Serviços Corporativos',
      subsegment: [
        { id: 140, name: 'Consultorias' },
        { id: 138, name: 'Marketing' },
        { id: 141, name: 'Segurança' },
        { id: 142, name: 'Serviços Corporativos - Outros' }
      ]

    },
    {
      id: 37,
      name: 'Serviços de Beleza e Estética',
      subsegment: [
        { id: 176, name: 'Clinicas de Estética e Massagem' },
        { id: 177, name: 'Hospitais e Laboratórios' },
        { id: 178, name: 'Medicina' },
        { id: 179, name: 'Odontologia' },
        { id: 180, name: 'Outras Clínicas' },
        { id: 181, name: 'Outros Serviços de Saúde' },
        { id: 182, name: 'Salão de Beleza' }
      ]

    },
    {
      id: 37,
      name: 'Serviços de Saúde e Clínicas',
      subsegment: [
        { id: 176, name: 'Hospitais e Laboratórios' },
        { id: 177, name: 'Medicina' },
        { id: 178, name: 'Odontologia' },
        { id: 179, name: 'Outras Clínicas' },
        { id: 180, name: 'Outros Serviços de Saúde' }
      ]
    },
    {
      id: 29,
      name: 'Serviços Financeiros',
      subsegment: [
        { id: 144, name: 'Serviços Financeiros' }
      ]
    },
    {
      id: 36,
      name: 'Subadquirente',
      subsegment: [
        { id: 163, name: '-' }
      ]
    },
    {
      id: 46,
      name: 'Supermercados',
      subsegment: [
        { id: 73, name: 'Supermercados' }
      ]
    },
    {
      id: 32,
      name: 'Telecomunicações',
      subsegment: [
        { id: 152, name: 'Telecomunicações' }
      ]
    },
    {
      id: 13,
      name: 'Varejo e Comércio',
      subsegment: [
        { id: 55, name: '-' }
      ]
    }
  ]
  return segmentList
}
