import { getMe } from './read.js'
import { setAnswer } from './create.js'
import { view } from './update.js'

/**
 * @namespace nps
 * @memberof UtilitiesCore
 * @instance
 */
export default function nps () {
  return {
    getMe: getMe.bind(this),
    setAnswer: setAnswer.bind(this),
    view: view.bind(this)
  }
}
